import React from 'react';
import '../App.css';
import About from './About';
import Form from './Form';
import Footer from './Footer';
import Header from './Header';
import Hero from './Hero';
import SocialMedia from './SocialMedia';
import Phuket from './Phuket';
import Navigation from './Navigation';

function LandingPage() {

  return (
    <div>
        <Header/>
        <Hero/>
        <Navigation/>
        <About/>
        <Phuket/>
        <SocialMedia/>
        <Form/>
        <Footer/>
    </div>
  );
}

export default LandingPage;
