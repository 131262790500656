import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import '../App.css';

const SocialMedia = () => {
  return (
    <div className="social-media-container">
      <p className="social-media-text">Follow us on social for updates.</p>
      <div className="social-media-icons">
        <a href={process.env.REACT_APP_FB_URL} target='blank'>
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <a href={process.env.REACT_APP_IG_URL} target='blank'>
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
