import React from "react";
import "../App.css";
import Header from "./Header";
import Footer from "./Footer";
import sample from "../chalimero-pics/bcis/SportsCenter_promovideo_min.mp4";
import poster from "../chalimero-pics/bcis/17.jpg";
import image1 from "../chalimero-pics/bcis/18.jpg";
import image2 from "../chalimero-pics/bcis/20.jpg";
import image3 from "../chalimero-pics/bcis/22.jpg";
import HomeNavigation from "./HomeNavigation";
import Contact from "./Contact";
import Wave from "./Wave";

const TrainingCamp = () => {
  return (
    <div className="training-camp">
      <Header />
      <HomeNavigation />
      <section id="hero" className="hero">
        <video poster={poster} className="hero-video" autoPlay loop muted playsInline>
          <source src={sample} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-content">
          <h1>SPORT – LEISURE – CULTURE</h1>
        </div>
      </section>
      <div className="training-container">
        <div className="training-camp-row dark">
          <div className="training-camp-column image-column">
            <img src={image1} alt="BCIS Gymnastics Hall" />
          </div>
          <div className="training-camp-column text-column">
            <p>
              Phuket offers plenty of sports opportunities, it is a true
              paradise for both competitive athletes and lovers of active
              leisure and recovery. Chalimero Tours organizes training camps for
              sports clubs, sports associations, or individual groups of
              athletes, who would like to train in a beautiful, exotic ambience,
              in order to prepare for a competition or just for recreational
              purposes. We plan, book, organize and co-ordinate the training
              schedule, venue, accommodation, meals -upon request -, leisure,
              cultural programs, and excursions.
            </p>
          </div>
        </div>
        <div className="training-camp-row light">
          <div className="training-camp-column text-column">
            <p>
              Training sessions run at BCIS Sports Center, located in Chalong,
              the heart of Phuket. BCIS is an accredited international school
              founded in 2017, and offers Cambridge International and National
              French Programme. The Sports Center on Senior Campus is a 2,500
              sqm multi-purpose facility, opened in 2023, built on three levels
              and designed to accommodate a wide range of sports. The Sports
              Center has professional Gymnova gymnasium, 2 indoor ski slopes,
              bowling alley, fitness zone, spinning and dance room,
              multi-functional basketball court (handball, volleyball,
              badminton, futsal) an outdoor 50-meter, FINA-certified Olympic
              swimming pool, changing and shower rooms. The Gymnastics Hall
              features a full range of brand new Gymnova equipment
              (FIG-certified) and is one of the finest gymnastics facilities in
              all of Thailand.
            </p>
          </div>
          <div className="training-camp-column image-column">
            <img src={image2} alt="BCIS indoor equipment" />
          </div>
        </div>
        <div className="training-camp-row dark">
          <div className="training-camp-column image-column">
            <img src={image3} alt="BCIS indoor skyslope" />
          </div>
          <div className="training-camp-column text-column">
            <h3>Training Camp Details</h3>
            <ul>
              <li>
                Minimum booking: 10 days 9 nights, including 6 training days.
                Duration of camp and number of training days can be customized.
              </li>
              <li>
                Accommodation in our 4-star partner hotel, close to BCIS Sport
                Center (400 meters), twin/double bed, air-conditioned Standard,
                Deluxe or Luxury rooms, including breakfast.
              </li>
              <li>
                Thai and international buffet lunch on training days in BCIS
                restaurant is included. Upon request, dinner service is
                available.
              </li>
              <li>
                Half-day and full-day programs, excursions (based on your
                choice).
              </li>
              <li>
                Transportation, comfortable, air-conditioned minivan is
                available between the airport and resort, as well as for
                excursions.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="notes-container">
        <p>
          Upon request we assist you to find airplane tickets. You will be
          provided with comprehensive, useful information and assistance before
          and during your stay.
        </p>
        <p>
          For our up-to-date brochure with more details, price calculation,
          quotation please do not hesitate to contact us:
        </p>
        <Contact />
      </div>
      <Wave />
      <Footer />
    </div>
  );
};

export default TrainingCamp;
