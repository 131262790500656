import React from 'react';
import LandingPage from './components/LandingPage';
import Training from './components/Training';
import Tours from './components/Tours';
import NotFound from './components/NotFound';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Wedding from './components/Wedding';

function App() {

  return (
    <BrowserRouter>
      <Routes>
            <Route index element={<LandingPage />} />
            <Route path="training" element={<Training />} />
            <Route path="tours" element={<Tours />} />
            <Route path="wedding" element={<Wedding />} />
            <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
