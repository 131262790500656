import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../App.css';

function Footer() {
  const location = useLocation();

  useEffect(() => {
    // Get the pathname from the current location
    const { pathname } = location;

    // Check if the pathname matches any of the specific pages
    if (['/tours', '/wedding', '/training'].includes(pathname)) {
      // Hide elements with class 'tat-credentials' by adding a class to them
      const tatCredentialsElements = document.querySelectorAll('.tat-credentials');
      tatCredentialsElements.forEach(element => {
        element.classList.add('hidden');
      });
    }
  }, [location]);

  return (
    <footer className="footer-container">
      <div className='tat-credentials'>TAT License No.: 34/03407<br/>DBD Reg. No.: 0835566015169</div>
      <p>&copy;  2024 Chalimero Creations Co. Ltd.</p>
    </footer>
  );
}

export default Footer;
