import React from "react";
import "../App.css";
import logoImage from "../chalimero-pics/logo/chalimeroLogo_landscape.png"
import { handleClickScroll } from "./utils";
import { useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();
  return (
    <header className="header-container">
      <div className="logo">
        <img src={logoImage} alt="Chalimero Creations Phuket Logo" />
      </div>
      <nav className="navigation-container">
        <ul>
          {location.pathname === "/" ? (
            <>
              <li>
                <p onClick={() => handleClickScroll("about")}> About </p>
              </li>
              <li>           
                  <p onClick={() => handleClickScroll("phuket")}> Phuket </p>
              </li>
              <li>
                <a href="/training">
                  <p> Training Camp </p>
                </a>
              </li>
              <li>
                <a href="/tours">
                  <p> Tours </p>
                </a>
              </li>
              <li>
                <a href="/wedding">
                  <p> Wedding </p>
                </a>
              </li>
              <li>
                <p onClick={() => handleClickScroll("contact")}> Contact </p>
              </li>
            </>
          ) : (
            <li>
              <a href="/">
                <p> Home </p>
              </a>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
}
export default Header;
