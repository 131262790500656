import React from 'react';
import '../App.css';
import { handleClickScroll } from "./utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faRing } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import Footer from './Footer';
import weddingImage from '../chalimero-pics/wedding/14.JPG';
import HomeNavigation from './HomeNavigation';
import Contact from './Contact';
import Wave from './Wave';

const Wedding = () => {
  return (
    <>     
    <Header />
    <HomeNavigation />
    <section id="wedding" className="wedding-section">
    <div className="wedding-container">
      <div className="image-container">
        <img src={weddingImage} alt="Wedding" />
      </div>
      <div className="content-container">
        <h1>Your dream wedding in Phuket</h1>
        <blockquote className='blockquote'>
        <p>The highest happiness on earth is the happiness of marriage.</p>
        <cite>William Lyon Phelps</cite>
        </blockquote>
        <p>
        Phuket is one of the most romantic islands in the world with its stunning palm tree-lined, white sand beaches, lush rainforests and mountainous landscape, the perfect location for a dream wedding! If you have a big dream of saying „I do!” in such a paradise environment, you are in the best place! A variety of exotic locations are available from a white sandy beach to a romantic chapel by the lake. We will take care of you, help you find the best solution for your taste, style, budget and ensure that everything is perfect on the big day.</p>
        <div className="scroll-button" onClick={() => handleClickScroll("wedding-services")} >Discover Our Services</div>
      </div>
    </div>
    <div id='wedding-services' className="wedding-services-container">
      <div className="wedding-column">
        <div className="column-title">
        <FontAwesomeIcon icon={faRing} className="icon" />
          Wedding Services
        </div>
        <ul className="service-list">
          <li>Wedding ceremony setup</li>
          <li>Floral decoration, flower arch</li>
          <li>Bridal bouquet and corsage for groom</li>
          <li>Wedding celebrant</li>
          <li>Wedding certificate (non-official)</li>
          <li>Sand ceremony</li>
          <li>Bridal Makeup & Hair</li>
          <li>Makeup & Hair for bridesmaids, groom and wedding guests</li>
          <li>Photographer service, 2-hour photoshoot session</li>
          <li>Videographer, short video of your wedding ceremony and photoshoot session</li>
          <li>A bottle of champagne for toasting</li>
          <li>Background music during the ceremony</li>
         </ul>
      </div>
      <div className="wedding-column">
        <div className="column-title">
        <FontAwesomeIcon icon={faHeart} className="icon" />
          Add-on options
        </div>
        <ul className="service-list">
          <li>Wedding cake</li>
          <li>Additional balloon decoration</li>
          <li>Romantic wedding dinner</li>
          <li>Wedding reception (Cocktail or Dinner catering)</li>
          <li>Live band, live music</li>
          <li>Wedding entertainment and activities (aerial, lyra, circus performance, fire show, games)</li>
        </ul>
      </div>
    </div>
    <div className='notes-container'>
      <p className="notes"><strong>Important notes:</strong></p>
      <ul>
        <li>Wedding package includes symbolic wedding ceremony and non-official certificate</li>
        <li>Arrangements subject to weather conditions</li>
      </ul>
      <p>Contact us today and start to plan your dream wedding in Phuket! Let us make your special day unforgettable!</p>
      <Contact />
    </div>
    <Wave />
    </section>
    <Footer />
    </>
  );
};

export default Wedding;
