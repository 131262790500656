import axios from 'axios';
import { useNavigate } from "react-router-dom";

export const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

export const handleSubmit = async (event, data, route) => {
    event.preventDefault();
    try {
      const response = await axios.post(route, data);
      const { token } = response.data;
      localStorage.setItem('token', token);
      // Redirect to dashboard or profile page
    } catch (error) {
      console.error(error);
    }
  };

  export const useRouteChange = (path) => {
    const navigate = useNavigate();
    navigate(path);
  };

  window.addEventListener('scroll', function() {
    var header = document.querySelector('.header-container');
    if (window.innerWidth > 768 && window.scrollY > 0) { // Show navbar if screen is larger than mobile size and scrolled down
      header.classList.add('hidden');
    } else {
      header.classList.remove('hidden');
    }
  });
  
  window.addEventListener('resize', function() {
    var header = document.querySelector('.header-container');
    if (window.innerWidth <= 768) { // Hide navbar on mobile size
      header.classList.add('hidden');
    } else {
      header.classList.remove('hidden');
    }
  });
  