import React from 'react';
import '../App.css';
import phuket from '../chalimero-pics/phuket/06.jpg'
import trainingCamp from '../chalimero-pics/bcis/18.jpg'
import tours from '../chalimero-pics/phuket/09.jpg'

function About() {
    const data = [
    { id: 1, alt:'phuket beach', imgUrl: phuket, title: 'Our Story', content: "At www.chalimerocreations.com, we're passionate about travel. During our vacations in Phuket, we were mesmerized by Phuket's cultural and gastronomic diversity, the wonderful mountainous and coastal scenery, the kindness of local people, and a unique, inclusive society that over-rides differences of race, religion and gender. The island offers plenty of sports opportunities, it is a true paradise for both competitive athletes and lovers of active leisure and recovery." },
    { id: 2, alt:'phuket training camp bcis', imgUrl: trainingCamp, title: 'Our Services', content: "We offer a range of services to make your travel experience seamless and stress-free. Athletes getting away from the ordinary training environment can train in a beautiful, exotic ambience, far from the noisy western world in order to prepare for a competition or just for recreational purposes. Chalimero Tours was established with the aim of organizing training camps for athletes, combined with cultural and leisure programs on this wonderful tropical island." },
    { id: 3, alt:'phuket historic temple', imgUrl: tours, title: 'Our Destinations', content: "We specialize in a variety of destinations around Phuket, from the sandy beaches of to the bustling cities around the island. We also provide assistance for individual travelers and groups in planning their dream holiday in Phuket, booking of accommodation, tours and excursions. If you are a couple in love and dream of a wonderful wedding in Thailand, we are ready to organize it! With many years of experience and affordable prices, we look forward to seeing you in Phuket!" }
  ];

  return (
  <section id="about">
    <h2 class="about">About Us</h2>
    <div className="grid-container">
      {data.map(item => (
        <div key={item.id} className="grid-item">
          <img src={item.imgUrl} alt={item.alt} className="round-image" />
          <h2 className='about-title'>{item.title}</h2>
          <p className='about-content'>{item.content}</p>
        </div>
      ))}
    </div>
  </section>
  );
}

export default About;
