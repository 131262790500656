import React from 'react';
import '../App.css';

const Mansory = () => {
  return (
    <div className="c-heroImageGrid">
      <div className="tours-container">
        <div className="column"></div>
        <div className="column">
          <div className="row"></div>
          <div className="row">
            <div>
              <div className="text">
                <h6>
                “The world is a book, and those who do not travel read only one
              page.”<br />
                  - Saint Augustine -
                </h6>
              </div>
            </div>
            <div></div>
          </div>
          <div className="row"></div>
        </div>
      </div>
    </div>
  );
}

export default Mansory;
