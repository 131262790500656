import React from "react";
import "../App.css";
import Header from "./Header";
import HomeNavigation from "./HomeNavigation";
import Footer from "./Footer";
import Contact from "./Contact";
import Mansory from "./Mansory";
import Wave from "./Wave";


function Tours() {
  return (
      <>
      <Header />
      <HomeNavigation />
      <Mansory/>
      <section id="tours" className="tours-section">
        <div className="container">
          <div className="highlighted-quote">
            <p className="quote">
              “The world is a book, and those who do not travel read only one
              page.”
              <br />
              - Saint Augustine -
            </p>
          </div>
          <div className="section section-light">
            <h2>Chalimero Tours</h2>
            <p>
              There are nearly 2,000 resorts, apartments and hotels in Phuket.
              Where to choose accommodation? Which part of the island is the
              best? Which is the most suitable/beautiful/quite/affordable etc.
              resort for me and my family? Which hotel is the best value for
              money? Where the food and drinks are the tastiest and most
              delicious? What sights and attractions should I see? Which places
              to visit and what are the best things to do in Phuket? Which
              excursions should I choose from the offer of thousands of program
              organizers? You have probably asked these questions before you
              selected a hotel or programs for your vacation and worried about
              whether you really chose the best options. No need to worry,
              Chalimero Tours is here to help you! With our partner hotels, the
              best price and quality for the perfect dream vacation are
              guaranteed!
            </p>
          </div>
          <div className="section section-dark">
            <h2>Why to Choose Chalimero Tours</h2>
            <p>
              You don't need to spend time with doing your research, sorting
              tons of resorts and options, you can save time and money. You can
              also avoid unpleasant surprise, scams, hidden costs and
              disappointment. With many years of experience in Phuket, we find
              you the solutions that best suit your taste, style and budget, to
              make your vacation special, relaxed and memorable. We assist you
              to find the best hotel, restaurants, excursions and take care of
              reservations, bookings and transportation. Upon request we also
              help you to find airplane tickets. You will be provided with
              comprehensive, useful information and assistance before and during
              your stay.
            </p>
          </div>
        </div>
        <div className="notes-container">
          <p>For reservations, bookings please contact us:</p>
          <Contact />
        </div>
      </section>
      <Wave />
      <Footer />
</>
  );
}

export default Tours;
