import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import '../App.css';

const HomeNavigation = () => {
  
  return (
    <Link to="/" className="home-navigation">
    <div>
      <FontAwesomeIcon icon={faHouse} />
    </div>
    </Link>
  );
};

export default HomeNavigation;
