import React from 'react';
import "../App.css";
import logo from "../chalimero-pics/logo/ChalimeroTours_logo.jpg";

function Contact() {
  return (
    <div className="contact-container">
      <p className="contact-info">
        <strong>Email:</strong> chalimerotours@gmail.com
        <br />
        <strong>Facebook:</strong> Chalimero Tours Phuket
        <br />
        <strong>Instagram:</strong> @chalimerotours_phuket
      </p>
      <p>TAT License No.: 34/03407<br/>DBD Reg. No.: 0835566015169</p>
      <div className="logo-container">
        <img src={logo} alt="Logo" className="tours-logo" />
      </div>
    </div>
  );
}

export default Contact;
