import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [sent, setSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    const userId = process.env.REACT_APP_PUBLIC_KEY;

    try {
      await emailjs.send(serviceId, templateId, formData, userId);
      console.log('Email sent successfully');
      setSent(true);
      setFormData({
        name: '',
        email: '',
        message: '',
        phone: ''
      });
    } catch (error) {
      console.error('Email sending failed:', error);
    }

    setSubmitting(false);
  };
  
  const handleClosePopup = () => {
    setSent(false);
  };

  return (
    <section id="contact" className="forms-section">
      {sent && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={handleClosePopup}>&times;</span>
            <p>Email sent successfully!</p>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="forms-container">
          <h2 className="form-title">Contact Us</h2>
          <p className='form-note'>For booking/enquiries do not hesitate to send message on Instagram, Facebook, email to 
          <span className="highlight-email"> chalimerotours@gmail.com</span> or fill out the form below!
          </p>
          <input type="text" name="name" value={formData.name} className="form-input" placeholder="Name*" onChange={handleChange} required/>
          <input type="email" name="email" value={formData.email} className="form-input" placeholder="Email*" onChange={handleChange} required/>
          <input type="tel" name="phone" value={formData.phone} className="form-input" placeholder="Phone" onChange={handleChange} />
          <textarea className="form-input" name="message" value={formData.message} placeholder="Message*" onChange={handleChange} required></textarea>
          <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} />
          <button type="submit" className={`form-submit ${submitting ? 'pulse' : ''}`} disabled={submitting}>
            {submitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </form>
    </section>
  );
};

export default Contact;
