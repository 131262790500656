import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function NotFound() {
  return (
    <div className="not-found">
      <h1>Oops!</h1>
      <p>The page you are looking for could not be found.</p>
      <div className="box">
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
      <Link to="/">Go back to Home</Link>
    </div>
  );
}

export default NotFound;
