import React from 'react';

function Wave() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path
        fill="#070707"
        fillOpacity="1"
        d="M0,192L40,192C80,192,160,192,240,192C320,192,400,192,480,176C560,160,640,128,720,117.3C800,107,880,117,960,138.7C1040,160,1120,192,1200,186.7C1280,181,1360,139,1400,117.3L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
      ></path>
    </svg>
  );
}

export default Wave;
