import React, { useState, useEffect } from "react";
import '../App.css';

function Navigation() {
    const [showNavigation, setShowNavigation] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setShowNavigation(true);
            } else {
                setShowNavigation(false);
            }
        };

        handleResize(); // Call it initially
        window.addEventListener('resize', handleResize); // Add event listener to handle resize

        return () => {
            window.removeEventListener('resize', handleResize); // Clean up event listener on component unmount
        };
    }, []); // Empty dependency array to ensure effect runs only once

    return (
        <div className={`navigation2 ${showNavigation ? 'visible' : ''}`}>
            <a href="/training"><p>Training Camp</p></a>
            <div className="nav2-separator"></div>
            <a href="/tours"><p>Tours</p></a>
            <div className="nav2-separator"></div>
            <a href="/wedding"><p>Wedding</p></a>
        </div>
    );
}

export default Navigation;