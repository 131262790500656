import sample from '../chalimero-pics/phuket/PhuketPromoVideo03_min.mp4';
import image from '../chalimero-pics/phuket/00.png';
import '../App.css';

function Hero() {
  return (
    <>
      <section id="hero" className="hero">
        <video poster={image} className="hero-video" autoPlay loop muted playsInline>
          <source src={sample} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-content">
          <h1>Your Travel Adventure Begins Here</h1>
          <p>Discover the beauty of Phuket with Chalimero Tours</p>
        </div>
      </section>
    </>
  );
}

export default Hero;
