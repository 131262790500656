import React from 'react';
import phuketImage1 from '../chalimero-pics/phuket/01.jpg'
import phuketImage2 from '../chalimero-pics/tours/13.jpg'
import phuketImage3 from '../chalimero-pics/phuket/04.jpg'
import phuketImage4 from '../chalimero-pics/phuket/12.jpg'
import '../App.css';

function Phuket() {
  return (
    <section id="phuket" className="section phuket">
    <div className="phuket-content">
      <div class="divider"><span></span><span>สวัสดี ครับ</span><span></span></div>
      <h2 className='phuket-title'>Discover the Unseen Corners of Phuket with Chalimero Tours</h2>
      <div className="phuket-grid">
        <div className="phuket-card">
          <img src={phuketImage2} alt={`Phuket Coast`} className="phuket-image" />
          <br></br>
          <img src={phuketImage4} alt={`Phuket Wildlife`} className="phuket-image" />
            <div className="phuket-card-overlay">
              <p>
                Phuket is frequently referred to as the pearl of the Andaman Sea. It is
                Thailand's largest island, where beaches surrounded by palm trees, lush
                rainforests, and the tranquility of impressive ancient temples mix with
                bustling cities and thriving nightlife.
              </p>
            </div>
          </div>
          <div className="phuket-card">
          <img src={phuketImage1} alt={`Phuket Big Buddha`} className="phuket-image" />
            <div className="phuket-card-overlay">
              <p>
              Phuket takes it all, sparkling sunshine, tropical rainforest, waterfalls, ancient monuments, golden temples, busy markets, sandy beaches lure visitors who can choose from countless activities. Water parks, dolphin shows, monkeys, elephant parks, spectacular Thai cultural shows, and lively nightlife attract many tourists.
              </p>
            </div>
          </div>
          <div className="phuket-card">
          <img src={phuketImage3} alt={`Phuket Sunset`} className="phuket-image" />
            <div className="phuket-card-overlay">
              <p>
              In Phuket, several nations' cuisine can be found, traditional Thai kitchen, European and Russian flavors or the taste of arabian cuisine, full of mouthwatering meals. The wonderful archipelago around Phuket impresses visitors with its picturesque landscapes and amazing flora and fauna. Those who love sailing and diving can explore diverse marine life with colorful coral reefs, turtles and dolphins.
              </p>
            </div>
          </div>
      </div>
    </div>
  </section>
  );
}

export default Phuket;
